<template>
  <div style="min-width: 1000px">
    <SearchForm @query="query" :config="searchConfig" />
    <a-table
      :scroll="{ x: '100%' }"
      :columns="columns" 
      :data-source="dataSource" 
      row-key="id"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
    >
      <template slot="takedPhotoUrl" slot-scope="takedPhotoUrl">
        <img v-if="takedPhotoUrl" :src="takedPhotoUrl" style="width: 50px; height: 50px" @click="currentTakedPhotoUrl = takedPhotoUrl" />
        <span v-else>无</span>
      </template>
      <template slot="recognitionImageUrl" slot-scope="recognitionImageUrl">
        <img v-if="recognitionImageUrl" :src="recognitionImageUrl" style="width: 50px; height: 50px" @click="currentTakedPhotoUrl = recognitionImageUrl" />
        <span v-else>无</span>
      </template>
      <template slot="action" slot-scope="record">
        <a-button type="link" style="padding: 0" @click="checkDetails(record)">查看</a-button>
      </template>
    </a-table>
    <template v-if="currentTakedPhotoUrl">
      <ImgPreview :url="currentTakedPhotoUrl" @hidImgPreview="currentTakedPhotoUrl = null" />
    </template>
    <a-drawer title="查看详情" width="600px" :visible="currentDetailsShow" :closable="false" :destroyOnClose="true">
      <a-descriptions :column="2" v-if="currentRecord">
        <a-descriptions-item label="人员姓名">{{ currentRecord.userName }}</a-descriptions-item>
        <a-descriptions-item label="电话">{{ currentRecord.userTel }}</a-descriptions-item>
        <a-descriptions-item label="区域">{{ currentRecord.areaName }}</a-descriptions-item>
        <a-descriptions-item label="房号">{{ currentRecord.houseNo }}</a-descriptions-item>
        <a-descriptions-item label="通行时间">{{ currentRecord.visitTime }}</a-descriptions-item>
        <a-descriptions-item label="门地址">{{ currentRecord.doorAddress }}</a-descriptions-item>
        <a-descriptions-item label="门名称">{{ currentRecord.doorName }}</a-descriptions-item>
        <a-descriptions-item label="用户类型">{{ findDataName(userTypeData, currentRecord.userType) }}</a-descriptions-item>
        <a-descriptions-item label="开门方式">{{ findDataName(openWaysData, currentRecord.openDirection) }}</a-descriptions-item>
        <a-descriptions-item label="开门类型">{{ openTypeMap[currentRecord.openType] }}</a-descriptions-item>
        <a-descriptions-item label="开门状态">{{ openResultMap[currentRecord.openResult] }}</a-descriptions-item>
        <a-descriptions-item label="注册图片">
          <img v-if="currentRecord.recognitionImageUrl" @click="currentTakedPhotoUrl = currentRecord.recognitionImageUrl" :src="currentRecord.recognitionImageUrl" style="width: 50px; height: 50px; cursor: pointer;" />
          <span v-else>无</span>
        </a-descriptions-item>
        <a-descriptions-item label="抓拍图片">
          <img @click="currentTakedPhotoUrl = currentRecord.takedPhotoUrl" v-if="currentRecord.takedPhotoUrl" :src="currentRecord.takedPhotoUrl" style="width: 50px; height: 50px; cursor: pointer;" />
          <span v-else>无</span>
        </a-descriptions-item>
      </a-descriptions>
      <div style="position: absolute;bottom: 0;right: 0; border-top: 1px solid #ccc; padding: 10px;width: 100%;flex-direction: row-reverse;display: flex;">
        <a-button type="primary" @click="currentDetailsShow = false">关闭</a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { getAccessRecords } from '@/api/access'
import ImgPreview from '@/components/ImgPreview'
import SearchForm from '@/components/SearchForm'

const userTypeData = [
  { id: '1', name: '业主' },
  { id: '2', name: '住户' },
  { id: '3', name: '访客' },
]
// 1进、2出、3其他
const openWaysData = [
  { id: '1', name: '进' },
  { id: '2', name: '出' },
  { id: '3', name: '其他' },
]
const openTypeMap = {
  1: '刷卡',
  2: '人脸',
  3: '指纹',
  4: '掌纹',
  5: '密码',
  6: '多卡开门',
  7: '远程开门',
  8: '二维码',
  9: '云坐席开门',
}

const findDataName = (data, id) => data.find(item => item.id === id)?.name
const openResultMap = { 1: '开门', 2: '无权限', 3: '多卡开门', 4: '确认开门', 5: '取消' }

const searchConfig = [
  {
    key: 'userName',
    label: '人员姓名',
    component: 'a-input',
    fieldProps: {
      allowClear: true,
      placeholder: '搜索人员姓名'
    }
  },
  {
    key: 'cardCheckedTime',
    label: '通行时间',
    component: 'a-range-picker',
    fieldProps: {
      allowClear: true,
      placeholder: ['开始时间', '结束时间'],
      showTime: true,
      format: 'YYYY-MM-DD HH:mm:ss'
    }
  },
  {
    key: 'areaName',
    label: '区域',
    component: 'a-input',
    fieldProps: {
      allowClear: true,
      placeholder: '搜索区域'
    }
  },
  {
    key: 'userTel',
    label: '电话',
    component: 'a-input',
    fieldProps: {
      allowClear: true,
      placeholder: '搜索电话'
    }
  },
  {
    key: 'houseNo',
    label: '房号',
    component: 'a-input',
    fieldProps: {
      allowClear: true,
      placeholder: '搜索房号'
    }
  },
  {
    key: 'userType',
    label: '用户类型',
    component: 'a-select',
    fieldProps: {
      allowClear: true,
      placeholder: '筛选用户类型'
    },
    options: userTypeData
  },
  {
    key: 'doorAddress',
    label: '门地址',
    component: 'a-input',
    fieldProps: {
      allowClear: true,
      placeholder: '搜索门地址'
    }
  },
  {
    key: 'doorName',
    label: '门名称',
    component: 'a-input',
    fieldProps: {
      allowClear: true,
      placeholder: '搜索门名称'
    }
  },
  {
    key: 'openDirection',
    label: '开门方式',
    component: 'a-select',
    fieldProps: {
      allowClear: true,
      placeholder: '筛选开门方式'
    },
    options: openWaysData
  },
  {
    key: 'openType',
    label: '开门类型',
    component: 'a-select',
    fieldProps: {
      allowClear: true,
      placeholder: '筛选开门类型'
    },
    options: Object.keys(openTypeMap).map(key => ({ id: key, name: openTypeMap[key] }))
  },
  {
    key: 'openResult',
    label: '开门状态',
    component: 'a-select',
    fieldProps: {
      allowClear: true,
      placeholder: '筛选开门状态'
    },
    options: Object.keys(openResultMap).map(key => ({ id: key, name: openResultMap[key] }))
  },
]

const columns = [
  { dataIndex: 'userName', title: '人员姓名', width: 100},
  { dataIndex: 'userTel', title: '电话', width: 130 },
  { dataIndex: 'areaName', title: '区域', width: 150 },
  { dataIndex: 'houseNo', title: '房号', width: 100 },
  { dataIndex: 'cardCheckedTime', title: '通行时间', width: 180 },
  { 
    dataIndex: 'recognitionImageUrl', 
    title: '注册图片',
    scopedSlots: { customRender: 'recognitionImageUrl' },
    width: 100
  },
  { 
    dataIndex: 'takedPhotoUrl', 
    title: '抓拍图片',
    scopedSlots: { customRender: 'takedPhotoUrl' }, 
    width: 100
  },
  { dataIndex: 'doorAddress', title: '门地址', width: 100 },
  { dataIndex: 'doorName', title: '门名称', width: 100 },
  { 
    dataIndex: 'userType', 
    title: '用户类型', 
    customRender: (text) => findDataName(userTypeData, text), 
    width: 100
  },
  { dataIndex: 'openDirection', 
    title: '开门方式', 
    customRender: (text) => findDataName(openWaysData, text), 
    width: 100
  },
  { 
    dataIndex: 'openType', 
    title: '开门类型',
    customRender: (text) => openTypeMap[text], 
    width: 100
  },
  { 
    dataIndex: 'openResult', 
    title: '开门状态',
    customRender: (text) => openResultMap[text], 
    width: 100
  },
  { title: '操作', width: '100px', scopedSlots: { customRender: 'action' } },
]

export default {
  components: {
    ImgPreview,
    SearchForm,
  },
  props: {
    projectId: {
      type: [Number, String],
      default: 0
    },
    channelId: {
      type: [Number, String],
      default: 0
    }
  },
  data(){
    return {
      searchConfig,
      columns,
      params: {},
      userTypeData,
      openWaysData,
      openTypeMap,
      openResultMap,
      pagination: {
        showTotal: (total) => '共 ' + total + ' 条',
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100', '500', '1000']
      },
      loading: false,
      dataSource: [],
      currentTakedPhotoUrl: null,
      currentDetailsShow: false,
      currentRecord: {}
    }
  },
  mounted(){
    this.fetch()
  },
  methods: {
    query(params){
      this.params = { ...params }
      this.fetch()
    },
    findDataName,
    checkDetails(record){
      this.currentDetailsShow = true
      this.currentRecord = record
    },
    handleTableChange (pagination) {
      this.pagination = { ...pagination }
      this.fetch()
    },
    fetch(){
      this.loading = true
      const { cardCheckedTime, ...restParams } = this.params
      if(Array.isArray(cardCheckedTime) && cardCheckedTime.length === 2){
        restParams.beginDate = cardCheckedTime[0].format('YYYY-MM-DD')
        restParams.endDate = cardCheckedTime[1].format('YYYY-MM-DD')
      }
      getAccessRecords({
        current: this.pagination.current,
        size: this.pagination.pageSize,
        projectId: this.projectId,
        orderByColumn: 'cardCheckedTime',
        keyPerson: 0,
        blackList: 0,
        ...restParams,
      }).then((result) => {
        if(result.code === 200){
          this.dataSource = result.records
          const pagination = { ...this.pagination }
          pagination.total = Number(result.total)
          this.pagination = pagination
        }else{
          this.dataSource = []
        }
      }).catch(() => {
        this.dataSource = []
      }).finally(() => {
        this.loading = false
      })
    },
    reset(){
      this.fetch()
    }
  }
}
</script>