<template>
  <div class="call-list-box" style="flex: 1;">
    <a-card
        v-for="item in calls"
        :key="item.id"
        :hoverable="true"
        :bodyStyle="{ padding: '10px'}"
        style="margin-bottom: 5px">
      <div>
        <div>
          <div style="font-weight: bold"> {{ item.parkingName }}</div>
          <div>
            <a-icon type="clock-circle"/> :
            <span style="font-size:15px ">{{ item.callTime }}</span>
          </div>
        </div>
      </div>
      <a-row style="margin-top: 5px">
        <a-col :span="6" :offset="14">
          <a-button type="danger" size="small" shape="circle" icon="poweroff" @click="$emit('reject-call', item.id)"/>
        </a-col>
        <a-col :span="4">
          <a-button type="primary" size="small" shape="circle" icon="phone" @click="$emit('answer-call', item.id)"/>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'CallList',
  props: {
    calls: {
      type: Array,
      defaultValue: ()=> []
    }
  },
  data(){
    return {
    }
  },
  computed: {
    ...mapGetters(['isHmAnswering', 'accessHmCalls'])
  },
  methods: {
  }
}
</script>

<style>
.call-list-box{
  overflow-y: auto;
  height: calc(100% - 50px);
}
</style>