import request from "../utis/request";

const accessApi = {
  getProjecDevice: '/access/ac/doortreeselect',
  getAccessRecords: '/access/accessRecords/list',
  getUserInfoList: '/access/userInfo/list',
  getMonitorAddress: '/access/doorParams/monitor-address/',
  callDevice: '/unattended/dh-call/active-call/',
  operationDoor: '/unattended/operation/access-door',
  screentPicture: '/unattended/operation/screenshot-upload',
  getDoorInfo: '/unattended/ac/getDoorInfo/',
  list: '/access/dict/data/list',
  getClientOrg: '/access/clientOrg/list',
}
// 获取区域设备
export function getProjecDevice (projectId) {
  return request({
    method: 'get',
    url: accessApi.getProjecDevice,
    params: {
      projectId,
    },
  })
}
// 查询通行记录
export function getAccessRecords (params) {
  return request({
    method: 'get',
    url: accessApi.getAccessRecords,
    params,
  })
}
// 查询住户信息
export function getUserInfoList (params) {
  return request({
    method: 'get',
    url: accessApi.getUserInfoList,
    params,
  })
}
// 获取监控地址
export function getMonitorAddress (doorId) {
  return request({
    method: 'post',
    url: accessApi.getMonitorAddress + doorId,
  })
}
// 门禁向下呼叫
export function callDevice (sipDeviceNo) {
  return request({
    method: 'post',
    url: accessApi.callDevice + sipDeviceNo,
  })
}
// 门禁操作
export function operationDoor (data) {
  return request({
    method: 'post',
    url: accessApi.operationDoor,
    data
  })
}
// 抓拍图片上传
export function screentPicture (data) {
  return request({
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url: accessApi.screentPicture,
    data
  })
}
// 获取门禁信息
export function getDoorInfo (doorId) {
  return request({
    method: 'get',
    url: accessApi.getDoorInfo + doorId,
  })
}
// 获取职位数据
export function getPositionData (params) {
  return request({
    method: 'get',
    url: accessApi.list,
    params
  })
}
// 获取组织
export function getClientOrg (data) {
  return request({
    method: 'post',
    url: accessApi.getClientOrg,
    data
  })
}