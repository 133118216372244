<template>
  <a-layout-header class="header">
    <div style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;height:100%">
      <div style="display: flex;align-items: center">
        <img class="log" src="~@/assets/hm_logo_tm.png"/>
        <span style="color: white;font-size: 12px;font-weight: bold">红轻停云坐席</span>&ndash;&gt;
      </div>
      <div>
        <a-dropdown style="margin-left: 10px">
          <a-menu slot="overlay">
            <a-menu-item key="2" @click="logout"> <a-icon type="logout" />退出登录</a-menu-item>
          </a-menu>
          <a-button type="link" style="margin-left: 8px;color: white;background-color: #001529">
              <span v-if="userInfo">
                {{ userInfo.username }}
              </span>
            <a-icon type="down" />
          </a-button>
        </a-dropdown>
      </div>
    </div>
  </a-layout-header>
</template>

<script>
import { Modal } from 'ant-design-vue'
import {mapGetters} from "vuex";

export default {
  name: "Head",
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  mounted() {
    this.$store.dispatch('getReason')
  },
  methods: {
    onRedress () {
      this.$refs.redressPlate.loadRedressPlate()
      this.redressPlateVisible = true
    },
    logout () {
      Modal.confirm({
        title: '确定退出登录吗?',
        onOk: ()=> {
          return this.$store.dispatch('Logout').then(() => {
              // this.$router.push({ name: 'Login' })
              window.location.href = '/login'
          })
        }
      })
    }
  }
}
</script>

<style scoped>
  .header {
    width: 100%;
    height: 50px;
  }
  .ant-layout-header{
    line-height: 0px;
    padding: 0 5px 0 20px;
  }
  .log {
    height: 40px;
    width: 40px;
    margin-right: 10px;
  }
  .heard_right {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
  }
</style>