<template>
  <div>
    <div style="display: flex;">
      <div style="width: 50%;padding: 10px;">
        <!-- <iframe v-if="monitorAddress" :src="monitorAddress" frameborder="0" style="width: 100%; height: 300px" /> -->
        <div class="video-picture-box empty-picture-box">
          <div class="impending-box">
            <img v-if="currentPassInfo && currentPassInfo.takedPhotoUrl" style="width: 100%;height: 100%;" :src="currentPassInfo.takedPhotoUrl" alt="">
            <img v-else style="width: 40%" :src="wptImage">
          </div>
        </div>
      </div>
      <div class="video-picture-box" style="width: 50%; padding: 10px;">
        <div class="video-picture-box">
          <div class="impending-box" style="background-color: #eee;">
            <a-spin v-if="videoLoading" />
            <iframe v-else-if="monitorAddress" :src="monitorAddress" frameborder="0" style="width: 100%; height: 100%" />
            <span v-else>未配置视频</span>
          </div>
        </div>
      </div>
    </div>

    <a-space class="btn-list-box">
      <a-button type="primary" :loading="loadingData.hoorOpenLoading" @click="doorOperation(3)">常开</a-button>
      <a-button type="primary" :loading="loadingData.openDoorLoading" @click="doorOperation(1)">开门</a-button>
      <a-button type="primary" :loading="loadingData.closeDoorLoading" @click="doorOperation(2)">关门</a-button>
      <a-button type="primary" :disabled="!isAnswering" @click="capture">抓拍</a-button>
    </a-space>
    
    <template v-if="passInfoLoadding">
      <a-skeleton active />
    </template>
    <a-descriptions v-else-if="currentPassInfo" title="通行信息">
      <a-descriptions-item label="通行证凭证类型">{{ openTypeMap[currentPassInfo.openType] }}</a-descriptions-item>
      <a-descriptions-item label="门名称">{{ currentPassInfo.doorName }}</a-descriptions-item>
      <a-descriptions-item label="门方向">{{ openWaysMap[currentPassInfo.openDirection] }}</a-descriptions-item>
      <a-descriptions-item label="通行时间">{{ currentPassInfo.visitTime }}</a-descriptions-item>
      <a-descriptions-item label="人员姓名">{{ currentPassInfo.userName }}</a-descriptions-item>
      <a-descriptions-item label="用户类型">{{ userTypeMap[currentPassInfo.userType] }}</a-descriptions-item>
      <a-descriptions-item label="区域">{{ currentPassInfo.areaName }}</a-descriptions-item>
      
      <a-descriptions-item label="注册图片">
        <img v-if="currentPassInfo.recognitionImageUrl" :src="currentPassInfo.recognitionImageUrl" style="width: 50px; height: 50px; cursor: pointer;" @click="currentImage = currentPassInfo.recognitionImageUrl" />
        <div v-else>无</div>
      </a-descriptions-item>
      <a-descriptions-item label="手机">{{ currentPassInfo.userTel }}</a-descriptions-item>
      <a-descriptions-item label="房号或组织">{{ currentPassInfo.houseNo }}</a-descriptions-item>
      <a-descriptions-item label="门地址">{{ currentPassInfo.doorAddress }}</a-descriptions-item>
    </a-descriptions>
    <template v-else>
      <span style="font-size: 16px;padding-left: 10px;margin-bottom: 10px;">暂无通行信息</span>
    </template>
    
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="历史通行记录">
        <Record ref="historyRecord" :projectId="projectId" :channelId="channelId" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="住户信息查询">
        <ResidentInfo ref="residentInfo" :projectId="projectId" :channelId="channelId" />
      </a-tab-pane>
    </a-tabs>

    <template v-if="currentImage">
      <ImgPreview :url="currentImage" @hidImgPreview="currentImage = null" />
    </template>
  </div>
</template>

<script>
import Record from './log/Record'
import ResidentInfo from './log/ResidentInfo'
import { getMonitorAddress, operationDoor, screentPicture } from '@/api/access'
import wptImage from '@/assets/wtp.png'
import { getAccessRecords } from '@/api/access'
import ImgPreview from '@/components/ImgPreview'
import { mapGetters } from 'vuex'
import { create } from 'tim-js-sdk'

const openTypeMap = {
  1: '刷卡',
  2: '人脸',
  3: '指纹',
  4: '掌纹',
  5: '密码',
  6: '多卡开门',
  7: '远程开门',
  8: '二维码',
  9: '云坐席开门',
}

const userTypeMap = {
  1: '业主',
  2: '住户',
  3: '访客',
}

const openWaysMap = {
  1: '进',
  2: '出',
  3: '其他'
}

export default {
  components: {
    Record,
    ResidentInfo,
    ImgPreview,
  },
  props: {
    projectId: {
      type: [Number, String],
      default: 0
    },
    channelId: {
      type: [Number, String],
      default: 0
    }
  },
  data(){
    return {
      openTypeMap,
      userTypeMap,
      openWaysMap,
      wptImage,
      captureShow: false,
      monitorAddress: '',
      videoLoading: false,
      currentPassInfo: null,
      passInfoLoadding: false,
      currentImage: null,
      loadingData: {
        openDoorLoading: false,
        hoorOpenLoading: false,
        closeDoorLoading: false,
      },
      captureRemark: '',
    }
  },
  computed: {
    ...mapGetters(['captureAction', 'currentAnswer', 'isAnswering'])
  },
  mounted(){
    this.initMonitorAddress()
    this.getCurrentPassInfo()
  },
  methods: {
    refresh(){
      this.initMonitorAddress()
      this.getCurrentPassInfo()
      if(this.$refs.historyRecord){
        this.$refs.historyRecord.reset()
      }
      if(this.$refs.residentInfo){
        this.$refs.residentInfo.reset()
      }
    },
    callOfDoor(){
      console.log('call')
    },
    capture(){
      const result = this.captureAction()
      if(result){
        // result是base64格式的图片
        result.then(res => {
          // 将file对象转换成可以显示的图片地址
          const url = URL.createObjectURL(res)
          const that = this
          this.$confirm({
            title: '抓拍图片',
            icon: () => <div></div>,
            okText: '上传',
            content: () => <div>
              <img src={url} style="width: 100%; margin-bottom: 10px;" />
              <a-form-item label="备注" labelCol={{ span: 3 }} wrapperCol={{ span: 20 }}>
                <a-textarea placeholder="请输入备注" v-model={that.captureRemark} style="margin-top: 10px" />
              </a-form-item>
            </div>,
            afterClose: () => {
              this.captureRemark = ''
            },
            onOk: () => {
              const formData = new FormData()
              formData.append('file', res)
              formData.append('parkingId', this.projectId)
              formData.append('channelId', this.channelId)
              formData.append('remark', that.captureRemark)
              formData.append('callId', that.currentAnswer?.id)
              screentPicture(formData).then(() => {
                this.$message.success('抓拍成功')
              }).catch(err => {
                this.$message.error(err.message)
              }).finally(() => {
                that.captureRemark = ''
              })
            },
          })
          
          // console.log(res)
        })
      }
      console.log('capture')
    },
    getCurrentPassInfo(){
      this.passInfoLoadding = true
      getAccessRecords({
        doorId: this.channelId,
        projectId: this.projectId,
        size: 1,
        current: 1,
      }).then(res => {
        if(res.code === 200){
          res.records
          if(res.records?.length){
            this.currentPassInfo = res.records[0]
          }
        }
      }).finally(() => {
        this.passInfoLoadding = false
      })
    },
    doorOperation(type){
      const operateMap = {
        1: '开门',
        2: '关门',
        3: '常开'
      }
      const loadingMap = {
        1: 'openDoorLoading',
        2: 'closeDoorLoading',
        3: 'hoorOpenLoading',
      }
      if(this.loadingData[loadingMap[type]]){
        return
      }
      this.loadingData[loadingMap[type]] = true
      const callId = this.currentAnswer ? this.currentAnswer.id : undefined
      operationDoor({
        parkingId: this.projectId,
        doorId: this.channelId,
        callId,
        action: type,
      }).then(() => {
        this.$message.success(operateMap[type] + '成功')
      }).catch(err => {
        this.$message.error(err.message)
      }).finally(() => {
        this.loadingData[loadingMap[type]] = false
      })
    },
    initMonitorAddress(){
      this.videoLoading = true
      this.monitorAddress = ''
      getMonitorAddress(this.channelId).then(res => {
        if(res){
          this.monitorAddress = res.monitorAddress
        }
      }).finally(() => {
        this.videoLoading = false
      })
    }
  }
}
</script>

<style>
.btn-list-box{
  display: flex;
  margin-bottom: 20px;
  padding-top: 10px;
}
.video-picture-box{
  padding-bottom: 56%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.empty-picture-box{
  background-color: #eee;
}
.impending-box{
  position: absolute; 
  width: 100%; 
  height: 100%; 
  top: 0; 
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>