<template>
  <div>
    <HeadGate />
    <div class="gate-content">
      <div class="gate-left-box">
        <div class="duty-call">
          <HmCall @register="register" @choose="chooseProjectInfo"/>
        </div>
        <div class="parking-duty-info" :style="{
          cursor: isAnswering ? 'not-allowed' : 'pointer',
          backgroundColor: '#001529'
        }" @click="goToParking">
          <h4>
            <svg style="position: relative; top: 4px;" t="1718351696813" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5153" width="20" height="20"><path d="M170.666667 533.333333L490.666667 213.333333l29.866666 29.866667-290.133333 290.133333 290.133333 290.133334-29.866666 29.866666L170.666667 533.333333z m298.666666 0L789.333333 213.333333l29.866667 29.866667-290.133333 290.133333 290.133333 290.133334-29.866667 29.866666-320-320z" fill="#fff" p-id="5154"></path></svg>
            <span style="padding-left: 5px;color: #fff;">点击前往停车坐席呼叫</span>
          </h4>
          <div>
            <a-badge :offset="[ -8, 7 ]" v-if="hmCalls.length + dhCalls.length" :count="hmCalls.length + dhCalls.length">
              <img class="phone-active-icon" :src="CallGreen" />
            </a-badge>
            <img :src="CallGray" v-else/>
          </div>
        </div>
      </div>
      <div class="gate-right-box">
        <div class="gate-select">
          <a-form layout="inline">
            <a-form-item label="当前项目">
              <a-tree-select
                v-model="currentProjectId"
                @select="onParkingChange"
                :disabled="isAnswering"
                :treeData="projects"
                treeNodeFilterProp='title'
                :show-search="true"
                style="width: 300px"
                :dropdown-style="{ maxHeight: '500px',height: '500px', overflow: 'auto' }"
                placeholder="请选择要操作的项目"
                allow-clear
                tree-default-expand-all
              >
              </a-tree-select>
            </a-form-item>
            <a-form-item label="门" v-if="channelTreeData && channelTreeData.length > 0">
              <a-tree-select
                v-model="currentChannelId"
                style="width: 200px"
                placeholder="请选择门"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto', fontSize: '16px' }"
                :disabled="(currentProjectId && false) || isAnswering"
                :tree-default-expand-all="true"
                :tree-data="channelTreeData"
                @change="onCurrentChannelChange"
              >
              </a-tree-select>
            </a-form-item>
            <a-form-item>
              <a-space>
                <a-button type="primary"  @click="getChannelInfo"  :disabled="!judgeBtnExist || isAnswering" >
                  立即获取门信息
                </a-button>
                <a-button v-if="channelInfoShow" @click="endDispose" ghost type="danger">结束处理</a-button>
                <a-button v-if="judgeBtnExist && currentChannelInfo && currentChannelInfo.sipDeviceNo" :disabled="isAnswering" @click="activeHmCall">HM对讲</a-button>
              </a-space>
            </a-form-item>
          </a-form>
        </div>
        <div class="choosed-content">
          <ChannelInfo ref="channelInfo" v-if="channelInfoShow" :projectId="currentProjectId" :channelId="currentChannelId" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeadGate from '@/components/HeadGate/Head.vue'
import CallGray from '@/assets/call-gray.svg'
import CallGreen from '@/assets/call-green.svg'
import HmCall from './HmCall'
import ChannelInfo from './ChannelInfo'
import pinyin from 'js-pinyin'
import { mapGetters } from 'vuex'
import { getProjecDevice, getDoorInfo } from '@/api/access'

export default {
  components: {
    HeadGate,
    HmCall,
    ChannelInfo,
  },
  data(){
    return {
      CallGray,
      CallGreen,
      currentProjectId: undefined,
      currentChannelId: undefined,
      projects: [],
      channelTreeData: [],
      expandChannel: false,
      channelInfoShow: false,
      hangUp: null,
      initiativeCall: null,
      currentChannelInfo: null,
    } 
  },
  computed: {
    ...mapGetters(['dutyParking', 'isAnswering', 'hmCalls', 'dhCalls']),
    judgeBtnExist(){
      return (this.currentChannelId || this.currentChannelId ===0) && (this.currentProjectId || this.currentProjectId === 0)
    },
  },
  mounted(){
    this.initProjectsData()
  },
  methods: {
    getDoorInfo(){
      this.currentChannelInfo = null
      getDoorInfo(this.currentChannelId).then(res => {
          this.currentChannelInfo = res
      })
    },
    activeHmCall(){
      if(this.initiativeCall){
        this.initiativeCall(this.currentChannelInfo.sipDeviceNo)
      }
    },
    chooseProjectInfo({ projectId, doorId }){
      this.currentProjectId = projectId
      this.onParkingChange(projectId)
      this.currentChannelId = Number(doorId)
      this.getChannelInfo()
    },
    register({ hangUp, initiativeCall }){
      this.hangUp = hangUp
      this.initiativeCall = initiativeCall
    },
    initProjectsData(){
      this.projects = this.dutyParking.map((p) => {
        return {
          ...p,
          label: p.name + '(' + pinyin.getCamelChars(p.name) + ')',
          value: p.parkingId,
        }
      })
    },
    onParkingChange (value) {
      this.currentChannelId = undefined
      if (value) {
        getProjecDevice(value).then(res => {
          if(Array.isArray(res)){
            this.channelTreeData = this.disposeAreaChannelData(res)
          }
        });
      }
      // this.channelErrorMessage = undefined
      // this.currentChannelId = null
      // this.channelAttr = null
    },
    disposeAreaChannelData(data){
      if(Array.isArray(data)){
        return data.map(item => {
          return {
            label: item.label,
            value: item.id,
            disabled: item.isDoor !== '1',
            children: this.disposeAreaChannelData(item.children)
          }
        })
      }else{
        return undefined
      }
    },
    onCurrentChannelChange(){
      
    },
    goToParking(){
      if(this.isAnswering){
        return
      }
      this.$router.push({ path: '/'})
    },
    getChannelInfo(){
      if(this.$refs.channelInfo){
        this.$refs.channelInfo.refresh()
      }
      this.getDoorInfo()
      this.channelInfoShow = true
    },
    endDispose(){
      this.hangUp()
      this.currentChannelInfo = null
      // this.$store.commit('SET_ANSWER', undefined)
      this.channelInfoShow = false
    }
  }
}

</script>


<style scoped>
.gate-content{
  height: calc(100vh - 50px);
  display: flex;
  border-bottom: 1px solid #aaa;
}
.gate-left-box{
  width: 25vw;
  height: calc(100vh - 50px);
  border-right: 1px solid #aaa;
  display: flex;
  flex-direction: column;
  background-color: #efefef;
}
.duty-call{
  flex: 1;
  height: calc(100% - 70px);
}
.parking-duty-info{
  height: 70px;
  padding: 15px;
  border-top: 1px solid #aaa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
}
.gate-right-box{
  width: 75vw;
  padding: 10px;
}
.gate-select{
  height: 60px;
  overflow-x: auto;
  white-space: nowrap;
}
.choosed-content{
  height: calc(100% - 60px);
  overflow-y: auto;
}
.phone-active-icon{
  animation: shake 2s infinite;
}
@keyframes shake {
  0% { transform: translate(0, 0) rotate(0); }
  40% { transform: translate(0, 0) rotate(0); }
  43% { transform: translate(2px, 2px) rotate(-15deg); }
  46% { transform: translate(-2px, -2px) rotate(15deg); }
  49% { transform: translate(2px, 2px) rotate(-15deg); }
  52% { transform: translate(-2px, -2px) rotate(15deg); }
  55% { transform: translate(2px, 2px) rotate(-15deg); }
  58% { transform: translate(-2px, -2px) rotate(15deg); }
  61% { transform: translate(2px, 2px) rotate(-15deg); }
  64% { transform: translate(-2px, -2px) rotate(15deg); }
  67% { transform: translate(2px, 2px) rotate(-15deg); }
  70% { transform: translate(-2px, -2px) rotate(15deg); }
  73% { transform: translate(2px, 2px) rotate(-15deg); }
  76% { transform: translate(-2px, -2px) rotate(15deg); }
  79% { transform: translate(0, 0) rotate(0); }
  100% { transform: translate(0, 0) rotate(0); }
}
</style>