<template>
  <div>
    <SearchForm :config="searchConfig" @query="query">
      <template #organizationId="{ formSource }">
        <a-tree-select
          show-search
          allow-clear
          v-model="formSource.organizationId"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="organizationData"
          placeholder="筛选组织"
          tree-default-expand-all
          :replace-fields="{children: 'childrenOrganization', title: 'nodeName', key: 'id', value: 'id'}"
          treeNodeFilterProp= "title"
        >
        </a-tree-select>
      </template>
    </SearchForm>
    <a-table
      :scroll="{ x: '100%' }"
      :columns="columns" 
      :data-source="dataSource" 
      row-key="id"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
    >
      <template slot="position" slot-scope="record">
        <span>{{ positionMapData[record.position] }}</span>
      </template>
    </a-table>
  </div>
</template>

<script>
import { getUserInfoList, getPositionData, getClientOrg } from '@/api/access'
import SearchForm from '@/components/SearchForm'

const userTypeData = [
  { id: '0', name: '业主（或者部门负责人）' },
  { id: '1', name: '住户（或员工）' },
  { id: '2', name: '游客' },
  { id: '3', name: '访客' },
  { id: '4', name: '物业' },
  { id: '5', name: '云坐席' },
]

const idCardTypeData = [
  { id: '1', name: '身份证' },
  { id: '2', name: '护照' },
  { id: '3', name: '驾驶证' },
]
const genderMapData = [
  { id: 1, name: '男' },
  { id: 2, name: '女' },
]

const fn = {
  registerPositionData: null
}
const registerRequestLogic = (fn, key) =>{
  fn[key] = fn
}

const getSearchConfig = (projectId) => {
  return [
    {
      key: 'userName',
      label: '姓名',
      component: 'a-input',
      fieldProps: {
        allowClear: true,
        placeholder: '搜索姓名'
      }
    },
    {
      key: 'houseNo',
      label: '房号',
      component: 'a-input',
      fieldProps: {
        allowClear: true,
        placeholder: '搜索房号'
      }
    },
    {
      key: 'userTel',
      label: '手机号',
      component: 'a-input',
      fieldProps: {
        allowClear: true,
        placeholder: '搜索手机号'
      }
    },
    {
      key: 'organizationId',
      label: '组织',
      component: 'slot',
      slotName: 'organizationId',
      // dynamicData: {
      //   target: 'tree-data',
      //   request(){
      //     return getClientOrg({
      //       parkingId: projectId,
      //     }).then(res => {
      //       if (Array.isArray(res)) {
      //         return res
      //       }
      //       return []
      //     })
      //   }
      // }
    },
    {
      key: 'idCardType',
      label: '证件类型',
      component: 'a-select',
      fieldProps: {
        allowClear: true,
        style: { width: '100%'},
        placeholder: '筛选证件类型'
      },
      options: idCardTypeData
    },
    {
      key: 'idCard',
      label: '证件号',
      component: 'a-input',
      fieldProps: {
        allowClear: true,
        placeholder: '搜索证件号'
      }
    },
    {
      key: 'userType',
      label: '用户类型',
      component: 'a-select',
      fieldProps: {
        allowClear: true,
        style: { width: '100%'},
        placeholder: '筛选用户类型'
      },
      options: userTypeData
    },
    {
      key: 'gender',
      label: '性别',
      component: 'a-select',
      fieldProps: {
        allowClear: true,
        style: { width: '100%'},
        placeholder: '筛选性别'
      },
      options: genderMapData
    },
    {
      key: 'position',
      label: '职位',
      component: 'a-select',
      fieldProps: {
        allowClear: true,
        style: { width: '100%'},
        placeholder: '筛选职位'
      },
      requestOption(){
        return getPositionData({
          dictType: 'ac_user_info_duties',
          orderByColumn: 'dict_code',
          projectId,
        }).then(res => {
          if(res.records && res.records.length > 0){
            this.positionData = res.records;
            if(fn.registerPositionData){
              fn.registerPositionData(res.records)
            }
            return res.records.map(item => ({
              id: item.dictValue,
              name: item.dictLabel
            }))
          }
        })
      }
    },
    {
      key: 'address',
      label: '地址',
      component: 'a-input',
      fieldProps: {
        allowClear: true,
        placeholder: '搜索地址'
      }
    },
    {
      key: 'userAccount',
      label: '编号',
      component: 'a-input',
      fieldProps: {
        allowClear: true,
        placeholder: '搜索编号'
      }
    }
  ]
}
const columns = [
  { dataIndex: 'userAccount', title: '编号', width: 120 },
  { dataIndex: 'userName', title: '姓名', width: 100, },
  { dataIndex: 'userTel', title: '电话', width: 130, },
  { 
    dataIndex: 'organizationInfo',
    title: '组织',
    customRender: (info) => {
      if(info && info.nodeName){
        return info.nodeName
      }
    },
    width: 120,
  },
  {
    title: '房号',
    dataIndex: 'acUserHousingRelList',
    customRender: (data) => {
      if(Array.isArray(data)){
        return data.map(item => {
          return item.acHousingManagement.houseNo
        }).join(',')
      }
    },
    width: 120,
  },
  { 
    dataIndex: 'userType', 
    title: '人员类型', 
    customRender: (text) => userTypeData.find(item => item.id === text)?.name,
    width: 190,
  },
  { 
    dataIndex: 'idCardType', 
    title: '证件类型',
    customRender: (text) => idCardTypeData.find(item => item.id === text)?.name,
    width: 90,
  },
  { dataIndex: 'idCard', title: '证件号', width: 190, },
  { 
    dataIndex: 'gender', 
    title: '性别',
    customRender: (text) => genderMapData.find(item => item.id === text)?.name,
    width: 60,
  },
  {
    title: '职位',
    dataIndex: 'position',
    scopedSlots: { customRender: 'action' },
    width: 120,
  },
  {
    title: '生日',
    dataIndex: 'birth',
    width: 140,
  },
  {
    title: '状态',
    dataIndex: 'userStatus',
    width: 120,
    customRender: (text) => ({ 0: '正常', 2: '注销' }[text])
  },
  {
    title: '地址',
    dataIndex: 'address',
    ellipsis: true,
    width: 160,
  }
]

export default {
  components: { SearchForm },
  props: {
    projectId: {
      type: [Number, String],
      default: 0
    },
    channelId: {
      type: [Number, String],
      default: 0
    }
  },
  data(){
    return {
      searchConfig: [],
      columns,
      params: {},
      pagination: {
        showTotal: (total) => '共 ' + total + ' 条',
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100', '500', '1000']
      },
      loading: false,
      dataSource: [],
      positionData: [],
      positionMapData: {},
      organizationData: [],
    }
  },
  mounted(){
    this.fetch()
    registerRequestLogic(this.initPositionData, 'registerPositionData')
    this.searchConfig = getSearchConfig(this.projectId)
    this.getOrgData()
  },
  methods: {
    getOrgData(){
      getClientOrg({
        parkingId: this.projectId,
      }).then(res => {
        if (Array.isArray(res)) {
          this.organizationData = res
        }
      })
    },
    initPositionData(data){
      const mapData = {}
      if(data && data.length > 0){
        data.forEach(item => {
          mapData[item.dictValue] = item.dictLabel
        })
      }
      this.positionMapData = mapData
    },
    query(params){
      this.params = { ...params }
      this.fetch()
    },
    handleTableChange (pagination) {
      this.pagination = { ...pagination }
      this.fetch()
    },
    fetch(){
      this.loading = true
      getUserInfoList({
        current: this.pagination.current,
        size: this.pagination.pageSize,
        projectId: this.projectId,
        orderByColumn: 'createdTime',
        ...this.params,
      }).then((result) => {
        if(result.code === 200){
          this.dataSource = result.records
          const pagination = { ...this.pagination }
          pagination.total = Number(result.total)
          this.pagination = pagination
        }else{
          this.dataSource = []
        }
      }).catch(() => {
        this.dataSource = []
      }).finally(() => {
        this.loading = false
      })
    },
    reset(){
      this.fetch()
    }
  }
}
</script>