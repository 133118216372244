<template>
  <div class="hm-call-box">
    <div class="hm-call-header">
      <div>红门呼叫</div>
      <div>
        <span v-if="onLine" style="color: #00CC33 ">登录红门呼叫成功</span>
        <span v-else>连接中...</span>
      </div>
      <div>
        <template v-if="onLine">
          <a-icon type="customer-service" theme="twoTone" two-tone-color="#52c41a" />
          <span>在线</span>
        </template>
        <template v-else>
          <a-icon type="customer-service" theme="twoTone" two-tone-color="#f5222d" />
          <span>离线</span>
        </template>
      </div>
    </div>
    <div v-if="isHmAnswering" class="answering">
      <div class="video_div" style="text-align: center">
        <div id="remote_stream" ref="remote_stream" style="height: 300px;display: flex; justify-content: center; align-items: center">
          <span v-if="this.currentAnswer.direction == 2 && this.streamLoding">
            呼叫中请稍后...
          </span>
        </div>
        <!-- <div id="local_stream" ref="local_stream" style="height: 200px;">
        </div> -->
      </div>
      <a-row class="calling_info" type="flex" justify="space-between" align="middle">
        <a-col :span="12" :offset="1"> {{ currentAnswer.parkingName }}({{ currentAnswer.channelName }})</a-col>
        <a-col :span="4">{{ talkSecond }}</a-col>
        <a-col :span="4">
          <a-button type="danger" size="small" @click="hangUp" >挂断</a-button>
        </a-col>
      </a-row>
    </div>
    <audio controls ref="notify" :src="ringPath" style="display: none" />
    <CallList v-if="!isHmAnswering && accessHmCalls.length" :calls="accessHmCalls" @answer-call="answerCall" @reject-call="rejectCall"/>
    <a-empty description="目前没有呼叫" v-if="!accessHmCalls.length && !isHmAnswering" style="padding-top: 30px"  />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CallList from './CallList'
import TRTC from 'trtc-js-sdk'
import { getUserSig } from '@/api/hmCall'
import TRTCCalling from 'trtc-calling-js';
import ringPath from "@/assets/huahai.mp3";

export default {
  components: {
    CallList,
  },
  computed: {
    ...mapGetters(['onLine', 'accessHmCalls', 'isHmAnswering', 'userInfo', 'currentAnswer', 'isRing']),
    talkSecond: function () {
      const time = parseInt(this.talkTime / 60)
      const second = this.talkTime % 60
      return (time < 10 ? ('0' + time) : time) + ':' + (second < 10 ? '0' + second : second)
    },
  },
  data(){
    return {
      ringPath,
      sdkAppId: 1400523210,
      streamLoding: false,
      userSig: '',
      talkTime: 0,
      IntervalTaskId: null,

      client: '',//客户端服务
      remoteStream: '',//远方播放流
      localStream: '',//本地流,

      trtcCalling: undefined,
    }
  },
  mounted(){
    this.getUserSig()
    this.registerEvent()
    this.registerGetPictureEvent()
  },
  watch: {
    accessHmCalls: {
      deep: true,
      handler (val) {
        this.setHasPhoneCall(val && val.length > 0)
      }
    },
    isRing(val) {
      if (val) {
        this.playRing();
      } else {
        this.stopRing();
      }
    },
  },
  onBeforeDestroy(){
    this.hangUp()
  },
  distoryed(){
    this.stopTalkTime()
    this.offCallingEvent()
  },
  methods: {
    ...mapActions(['answerHmCall', 'activeDhCall', 'hangUpHmCall', 'rejectHmCall','setHasPhoneCall', 'activeHmCall']),
    registerEvent(){
      this.$emit('register', {
        hangUp: () => this.hangUp(),
        initiativeCall: (params) => this.initiativeCall(params),
      })
    },
    capturePicture(){
      // 通过remote_stream里面的video标签获取视频的一帧作为截图
      const video = this.$refs.remote_stream.querySelector('video')
      if (video) {
        const canvas = document.createElement('canvas')
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight
        const ctx = canvas.getContext('2d')
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
        // 返回file对象
        return new Promise((resolve) => {
          canvas.toBlob((blob) => {
            resolve(new File([blob], 'capture.jpg'))
          }, 'image/jpeg')
        })
      }else{
        this.$message.warn('视频还在加载中，请稍后再试')
      }
    },
    registerGetPictureEvent(){
      this.$store.commit('SET_CAPTURE_EVENT', () => {
        return this.capturePicture()
      })
    },
    playRing() {
      if (!this.isAnswering) {
        this.$refs.notify.currentTime = 0;
        this.$refs.notify.play();
      }
    },
    stopRing() {
      this.$refs.notify.pause();
    },
    subscribeEvent(){
      this.$store.commit('SET_ACCESS_PLAY_EVENT', () => {
        this.playRing()
      })
    },
    initiativeCall (deviceNo) {
      if (this.onLine) {
        console.info('device no :' + deviceNo)
        this.activeHmCall(deviceNo).catch(e => {
          this.$message.error('红门向下呼叫获取通道停车场信息异常：' + e.message)
        }).finally(() => {
          const callResult = this.trtcCalling.call({userID: deviceNo, type: TRTCCalling.CALL_TYPE.VIDEO_CALL})
          callResult.then(e => {
            console.warn('主动呼叫成功');
            console.warn(e)
            this.startTalkTime()
            this.streamLoding = true
            // this.trtcCalling.startLocalView( { userID: this.userInfo.userid + '', videoViewDomID: 'local_stream' })
          }).catch(e => {
            console.info('主动呼叫失败')
            console.warn(e)
          })
        })
      } else {
        this.$message.error('您不在线无法呼叫');
      }
    },
    answerCall(id) {
      const call = this.accessHmCalls.find(item => item.id === id)
      this.answerHmCall(call).catch(e => {
        console.error('接听红门呼叫异常')
        console.error(e)
      }).finally(() => {
        this.handlerAnswerCall()
        this.joinRoom(call.deviceNo)
        this.startTalkTime()
      })
    },
    rejectCall(id){
      const call = this.accessHmCalls.find(item => item.id === id)
      this.rejectHmCall(call).catch(e => {
        console.error('拒接红门呼叫异常')
        console.error(e)
      }).finally(() => {
        const calls = this.accessHmCalls.filter(item => {
          item.id !== id
        })
        this.$store.commit('SET_ACCESS_HM_CALLS', calls)
      })
    },
    joinRoom (deviceNo) {
      this.createClient()
      this.subscribeStream(this.client)
      this.client.join({ 
        roomId: parseInt(deviceNo) 
      }).then(() => {
        console.debug('进房成功');
        //播放远端流
        this.playStream(this.client)
        //创建本地流
        this.createStream(this.userInfo.userid + '')
      }).catch(error => {
        console.error('进房失败 ' + error);
      });
    },
    createStream (userId) {
      const localStream = TRTC.createStream({ userId, audio: true, video: false });
      this.localStream = localStream
      localStream.initialize().then(() => {
        console.debug('初始化本地流成功');
        // 创建好后才能播放 本地流播放 local_stream 是div的id
        localStream.play('local_stream');
        //创建好后才能发布
        this.publishStream(localStream, this.client)
      }).catch(error => {
        console.error('初始化本地流失败 ' + error);
      })
    },
    publishStream (localStream, client) {
      client.publish(localStream).then(() => {
        console.debug('本地流发布成功');
      }).catch(error => {
        console.error('本地流发布失败 ' + error);
      });
    },
    playStream(client){
      client.on('stream-subscribed', event => {
        const remoteStream = event.stream;
        console.debug('远端流订阅成功：' + remoteStream.getId());
        // 创建远端流标签，因为id是动态的，所以动态创建，用了v-html
        //做了dom操作 需要使用$nextTick(),否则找不到创建的标签无法进行播放
        this.$nextTick(() => {
          //播放
          remoteStream.play('remote_stream', { objectFit: 'contain'}).catch(e => {
            this.$notification.error({
              message: '播放车道音视频失败',
              description: '可能是车道设备离线或故障：' + e.message,
              duration: null
            })
          });
        })
      });
    },
    subscribeStream (client) {
      console.debug('订阅远端流')
      client.on('stream-added', event => {
        const remoteStream = event.stream;
        console.debug('远端流增加: ' + remoteStream.getId());
        client.subscribe(remoteStream, { audio: true, video: true }).then(() => {
          console.debug('订阅远端流成功')
        }).catch(e => {
          console.error('订阅远端流失败' + e)
        });
      });
    },
    createClient () {
      this.client = TRTC.createClient({
        mode: 'rtc',
        sdkAppId: this.sdkAppId,
        userId: this.userInfo.userid + '',
        userSig: this.userSig
      });
      this.client.on('peer-leave', event => {
        console.debug('远端用户退出事件' + event)
        this.leaveRoom()
        this.$notification['warning']({
          placement: 'topLeft',
          message: '提示',
          description: '用户挂断了呼叫'
        })
      });
    },
    getUserSig () {
      getUserSig().then((res) => {
        this.userSig = res
        this.callingLogin()
        this.initCallingEvent()
      }).catch(e => {
        this.$notification.open({
          message: '连接呼叫中心失败，请联系研发同事。',
          description: e.message,
          icon: <a-icon type="close-circle" style="color: red" />
        });
        this.$no
        this.$notification.error({
          title: '红门呼叫登录失败',
          description: '获取用户签名错误：' + e.message
        })
      })
    },
    initCallingEvent () {
      this.trtcCalling.on(TRTCCalling.EVENT.ERROR, (e) => {
        console.error('calling 异常')
        console.warn(e)
      })
      this.trtcCalling.on(TRTCCalling.EVENT.REJECT, (e) =>  {
        console.warn('被邀用户拒绝')
        console.warn(e)
        this.$message.error({
          title: '设备拒绝了通话'
        })
        this.hangUp()
      });
      this.trtcCalling.on(TRTCCalling.EVENT.NO_RESP, (e) =>  {
        console.warn('被邀用户没有应答')
        console.warn(e)
        this.$notification.error({
          placement: 'topLeft',
          message: '设备忙线中',
          description: '无法接通，可稍后重试'
        })
      });
      this.trtcCalling.on(TRTCCalling.EVENT.LINE_BUSY, (e) =>  {
        console.warn('被邀用户忙线')
        console.warn(e)
        this.$message.error({
          title: '设备正在通话中，请稍后再试'
        })
        this.hangUp()
      });
      this.trtcCalling.on(TRTCCalling.EVENT.CALL_END, (e) => {
        console.warn('通话结束')
        console.warn(e)
        this.hangUp()
      });
      this.trtcCalling.on(TRTCCalling.EVENT.USER_ENTER, (e) => {
        console.warn('用户加入房间')
        console.warn(e)
      });
      this.trtcCalling.on(TRTCCalling.EVENT.USER_VIDEO_AVAILABLE, (e) => {
        console.warn('远端用户开启/关闭了摄像头')
        console.warn(e)
        this.trtcCalling.startRemoteView( { userID: this.currentAnswer.deviceNo, videoViewDomID: 'remote_stream' })
      });
      this.trtcCalling.on(TRTCCalling.EVENT.USER_AUDIO_AVAILABLE, (e) => {
        console.warn('远端用户开启/关闭了麦克风')
        console.warn(e)
        this.streamLoding = false
      });
    },
    hangUp () {
      const tempAnswer = this.currentAnswer
      this.hangUpHmCall().finally(() => {
        this.stopTalkTime()
        if (tempAnswer?.direction === 1) {
          this.leaveRoom()
        } else {
          this.trtcCalling.hangup()
        }
        const deviceNo = tempAnswer?.deviceNo
        if(deviceNo){
          const calls = this.accessHmCalls.filter(item => {
            return item.deviceNo !== deviceNo
          })
          this.$store.commit('SET_ACCESS_HM_CALLS', calls)
        }
      })
    },
    startTalkTime () {
      this.talkTime = 0
      this.IntervalTaskId = setInterval(() => {
        this.talkTime += 1
      }, 1000)
    },
    stopTalkTime () {
      if (this.IntervalTaskId) {
        clearInterval(this.IntervalTaskId);
      }
      this.talkTime = 0
    },
    leaveRoom () {
      if(this.client){
        this.client.leave().then(() => {
          console.debug('退房成功')
          // 停止本地流，关闭本地流内部的音视频播放器
          this.localStream.stop();
          // 关闭本地流，释放摄像头和麦克风访问权限
          this.localStream.close();
          this.localStream = null;
          this.client = null
        }).catch(error => {
          // 错误不可恢复，需要刷新页面。
          console.error('退房失败 ' + error);
          window.location.reload()
        }).finally(() => {
          // 防止挂掉websocket没有hangUp类型的消息，导致呼叫状态一直存在
          const deviceNo = this.currentAnswer.deviceNo
          if(deviceNo){
            const calls = this.accessHmCalls.filter(item => {
              return item.deviceNo !== deviceNo
            })
            this.$store.commit('SET_ACCESS_HM_CALLS', calls)
          }
          // this.$store.commit('SET_ANSWER', undefined)
          this.stopTalkTime()
        })
      }
    },
    async callingLogin () {
      this.trtcCalling =  new TRTCCalling({
        SDKAppID: this.sdkAppId
      })
      await this.trtcCalling.login({
        userID: this.userInfo.userid + '',
        userSig: this.userSig
      })
    },
    handlerAnswerCall() {
      console.log('this.currentAnswer', this.currentAnswer, this.currentAnswer.parkingId);
      if(this.currentAnswer && this.currentAnswer.parkingId) {
        this.$emit('choose', {
          projectId: this.currentAnswer.parkingId,
          doorId: this.currentAnswer.channelId,
        })
      }
    },
    offCallingEvent () {
      if (this.trtcCalling) {
        this.trtcCalling.off(TRTCCalling.EVENT.ERROR)
        this.trtcCalling.off(TRTCCalling.EVENT.INVITED)
        this.trtcCalling.off(TRTCCalling.EVENT.REJECT);
        this.trtcCalling.off(TRTCCalling.EVENT.NO_RESP);
        this.trtcCalling.off(TRTCCalling.EVENT.LINE_BUSY);
        this.trtcCalling.off(TRTCCalling.EVENT.CALL_END);
        this.trtcCalling.off(TRTCCalling.EVENT.USER_ENTER);
        this.trtcCalling.off(TRTCCalling.EVENT.USER_AUDIO_AVAILABLE);
        this.trtcCalling.logout()
        this.trtcCalling = undefined
      }
    }
  },
}

</script>


<style>
.hm-call-box{
  height: 100%;
}
.hm-call-header{
  height: 50px;
  background-color: #fff;
  padding: 15px;
  display: flex;
  justify-content: space-between;
}
.calling_info {
   padding: 8px 0;
   background-color: white;
 }
</style>