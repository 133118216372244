<template>
  <div>
    <div style="width: 100%;" @keyup.enter="handleEnterKey" class="table-page-search-wrapper search-form">
      <a-form-model layout="inline" >
        <a-row :gutter="48">
          <a-col :md="8" :sm="24" v-for="(item,index) in config" :key="item.key">
            <template v-if="index < 2 || advanced">
              <a-form-model-item :label="item.label" style="width: 100%; display: flex;">
                <slot v-if="item.component === 'slot'" :name="item.slotName" :formSource="formSource"></slot>
                <template v-else>
                  <component
                    :is="item.component"
                    style="width: 100%;"
                    v-model="formSource[item.key]"
                    v-bind="item.fieldProps ? item.fieldProps : {}"
                    v-on="item.listeners ? item.listeners : {}"
                  >
                    <template v-if="item.component === 'a-select'">
                      <template v-if="Array.isArray(item.options)">
                        <a-select-option v-for="el in item.options" :value="el.id" :key="el.id">
                          {{ el.name }}
                        </a-select-option>
                      </template>
                      <template v-else-if="Array.isArray(dataset[item.key])">
                        <a-select-option v-for="el in dataset[item.key]" :value="el.id" :key="el.id">
                          {{ el.name }}
                        </a-select-option>
                      </template>
                    </template>
                  </component>
                </template>
                
              </a-form-model-item>
            </template>
          </a-col>
          <a-col :md="!advanced && 8 || 24" :sm="24">
            <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
              <a-space>
                <a-button type="primary" icon="search" @click="query">查询</a-button>
                <a-button @click="reset">重置</a-button>
                <slot name="download"></slot>
                <a @click="toggleAdvanced" v-if="config.length > 2">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </a-space>
            </span>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    config: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      formSource: {}, // form data source
      dataset: {}, // dyamic data for select component
      advanced: false,
      downloadLoading: false,
    }
  },
  watch: {
    config(){
      this.initFormValue()
      this.initRequestOption()
    }
  },
  mounted () {
    this.initFormValue()
    this.initRequestOption()
  },
  methods: {
    initFormValue () {
      this.config.forEach(item => {
        if (item.defaultValue !== undefined && item.defaultValue !== null) {
          this.formSource[item.key] = item.defaultValue
        }
      })
    },
    initRequestOption () {
      this.config.forEach(item => {
        if (item.requestOption && Object.prototype.toString.call(item.requestOption) === '[object Function]') {
          this.requestOptionData(item)
        }
      })
    },
    requestOptionData (item) {
      const content = item.requestOption()
      if (Object.prototype.toString.call(content) === '[object Promise]') {
        content.then(data => {
          this.dataset[item.key] = data
          this.dataset = { ...this.dataset }
        })
        console.log('dataset', this.dataset)
      } else if (Object.prototype.toString.call(content) === '[object Array]') {
        this.dataset[item.key] = content
        this.dataset = { ...this.dataset }
      }
    },
    reset () {
      this.formSource = {}
      this.initFormValue()
      this.$emit('query', this.formSource)
    },
    handleEnterKey () {
      this.$emit('query', { ...this.formSource })
    },
    query () {
      this.$emit('query', { ...this.formSource })
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    // ref实例可调用
    resetFields () {
      this.formSource = {}
      this.initFormValue()
    },
     // ref实例可调用
    setFiledsValue (data) {
      if (Object.prototype.toString.call(data) === '[object Object]') {
        this.formSource = cloneDeep(data)
      }
    },
     // ref实例可调用
    setFiledValue (key, value) {
      this.$set(this.formSource, key, value)
    },
  }
}
</script>

<style>
.search-form .ant-form-item-control-wrapper{
  flex: 1;
}
</style>
