<template>
  <div class="imgBg">
    <a-icon v-if="(this.url instanceof Array)" type="left" class="leftIcon" @click="clickLeft" :style="{'color': imgUrl.index==0?'#8f8c8c':'white'}"/>
    <a-icon @click="hidImg" class="delSty" type="close" />
    <img ref="pic" :src="imgUrl.url" style="min-width: 400px;min-height: 400px;max-width: 90%;max-height: 90%;">
    <a-icon v-if="(this.url instanceof Array)" type="right" class="rightIcon" @click="clickRight" :style="{'color': (imgUrl.index==url.length-1)?'#8f8c8c':'white'}"/>
  </div>
</template>

<script>
export default {
	props: {
		url: {
			type: [String, Array],
			default: ''
		},
		current: {
			type: Number,
			default: 0
		}
	},
	data () {
		return {
			imgUrl: {
				url: '',
				index: 0
			},
			outsideIndex: 0,
		}
	},
	watch: {
		url () {
			this.setImgUrl()
		},
		current () {
			this.outsideIndex = this.current
			this.setImgUrl()
		}
	},
	methods: {
		hidImg () {
			this.$emit('hidImgPreview', false)
		},
		clickLeft () {
			if (this.imgUrl.index == 0) return 
			this.imgUrl.index = this.imgUrl.index - 1
			this.imgUrl.url = this.url[this.imgUrl.index]
		},
		clickRight () {
			if (this.imgUrl.index == this.url.length - 1) return
			this.imgUrl.index = this.imgUrl.index + 1
			this.imgUrl.url = this.url[this.imgUrl.index]
		},
		setImgUrl () {
			if (!this.url) return
			if (typeof (this.url) === 'string') {
				this.imgUrl.url = this.url
			} else {
				this.imgUrl.url = this.url[this.outsideIndex]
				this.imgUrl.index = this.outsideIndex
			}
		},
		setScaleImg() {
			let scale = 1;
			let pic = this.$refs.pic
			pic.onwheel = function (e) {
				if (e.wheelDelta > 0) {
					scale += 0.05;
					pic.style.transform = `scale(${scale})`;
				} else {
					if(scale<0.3)return
					scale -= 0.05;
					pic.style.transform = `scale(${scale})`;

				}
			};
		}
	},
	mounted() {
		this.setScaleImg()
	},
	created () {
		if (this.current) {
			this.outsideIndex = this.current
		}
		this.setImgUrl()
	}
}
</script>

<style scoped>
.imgBg {
	position: fixed;
	top: 0;
	width: 100%;
	left: 0;
	background: #1a1a1a9e;
	z-index: 9999;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	
}
.leftIcon{
	font-size: 60px;
	position: absolute;
	left: 20px;
	color: white;
	cursor: pointer;
}
.rightIcon{
	font-size: 60px;
	position: absolute;
	right: 20px;
	color: white;
	cursor: pointer;
}
.delSty {
	position: absolute;
	top: 20px;
	right: 20px;
	width: 50px;
	cursor: pointer;
	color: white;
	font-size: 40px;
}
</style>
